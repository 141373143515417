<template>
  <div class="sof-box-types main-text bold display-table">
    <div class="table-row">
      <div class="table-cell">
        <GlScoreDot
          class="mr-2"
          :score="100"
          width="6px"
        />
      </div>

      <GlTag
        class="mr-2 table-cell"
        :score="100"
        :show-score="false"
        tag="Darknet Marketplace"
      />
      <div class="table-cell">
        <span class="mr-1">100 BTC</span>
      </div>
      <div class="table-cell text-end-last">
        <span class="ml-1"> 86%</span>
      </div>
    </div>
    <div class="table-row">
      <div class="table-cell">
        <GlScoreDot
          class="mr-2"
          :score="50"
          width="6px"
        />
      </div>

      <GlTag
        class="mr-2 table-cell"
        :score="50"
        :show-score="false"
        tag="Exchange"
      />
      <div class="table-cell">
        <span class="mr-1">10 BTC</span>
      </div>
      <div class="table-cell text-end-last">
        <span class="ml-1"> 10%</span>
      </div>
    </div>
    <div class="table-row">
      <div class="table-cell">
        <GlScoreDot
          class="mr-2"
          :score="1"
          width="6px"
        />
      </div>
      <GlTag
        class="mr-2 table-cell"
        :score="1"
        :show-score="false"
        tag="Low-risk Exchange"
      />
      <div class="table-cell">
        <span class="mr-1"> 1 BTC</span>
      </div>
      <div class="table-cell text-end-last">
        <span class="ml-1"> 1%</span>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlScoreDot from '@/components/gl-score-dot'
import GlTag from '@/components/gl-tag'

export default {
  components: {
    GlScoreDot,
    GlTag,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style>
.sof-box-types {
  font-size: 8px !important;
  padding-top: 8px;
}
.sof-box-types .tag {
  padding: 3px 6px;
  font-size: 8px !important;
}
@media (max-width: 1023px) {
  .table-row {
    height: 24px;
  }
}
</style>
